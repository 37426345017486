<template>
  <div style="background: white" id="aa">
    <Loading :loadingCounter="loadingCounter" />
    <v-sheet class="overflow-hidden">
      <NavBar tittle="label.lbl_barcode_master" />
      <!-- バーコードマスタ画面 -->
      <v-form ref="form">
        <v-container
          class="d-flex justify-center align-center"
          fluid
          style="min-height: calc(100vh - 48px)"
        >
          <v-row class="justify-center">
            <v-col cols="12" sm="4" md="3">
              <v-card
                :class="{ 'disabled-card': isCompleteFlg }"
                height="200"
                class="pa-4"
                outlined
                hover
                color="#dee6f3"
                style="display: flex; justify-content: center; align-items: center"
                @click="pageTransition('qr')"
              >
                <v-card-title class="text-center" style="font-size: 32px; font-weight: bold">{{
                  $t("label.lbl_barcode_info_add")
                }}</v-card-title>
              </v-card>
            </v-col>
            <v-col cols="12" sm="4" md="3">
              <v-card
                :class="{ 'disabled-card': isCompleteFlg }"
                height="200"
                class="pa-4"
                outlined
                hover
                color="#dee6f3"
                style="display: flex; justify-content: center; align-items: center"
                @click="pageTransition('ht')"
              >
                <v-card-title class="text-center" style="font-size: 32px; font-weight: bold">{{
                  $t("label.lbl_ht_screen_control")
                }}</v-card-title>
              </v-card>
            </v-col>
            <v-col cols="12" sm="4" md="3">
              <v-card
                :class="{ 'disabled-card': isCompleteFlg }"
                height="200"
                class="pa-4"
                outlined
                hover
                color="#dee6f3"
                style="display: flex; justify-content: center; align-items: center"
                @click="pageTransition('label')"
              >
                <v-card-title class="text-center" style="font-size: 32px; font-weight: bold">{{
                  $t("label.lbl_ht_printing_control")
                }}</v-card-title>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
      <v-navigation-drawer absolute temporary v-model="openMenu">
        <sideMenu></sideMenu>
      </v-navigation-drawer>
    </v-sheet>
  </div>
</template>
<script>
import Loading from "@/components/loading";
import sideMenu from "@/components/Menu";
import NavBar from "../../components/NavBar.vue";
import { appConfig } from "../../assets/scripts/js/AppConfig";
import commonMixin from "@/mixins/CommonMixin";

export default {
  name: appConfig.SCREEN_ID.P_INI_004,
  components: {
    Loading,
    sideMenu,
    NavBar,
  },
  props: {
    isOpenDetailDialog: { type: Boolean, default: false },
    isMenu: { type: Boolean, default: false },
    entity: { type: Object, default: null },
  },
  mixins: [commonMixin],
  data: () => ({
    openMenu: false,
    isCompleteFlg: true,
  }),
  methods: {
    init() {
      this.getOfficeModule();
    },
    getOfficeModule() {
      // ローディング画面表示ON
      this.loadingCounter = 1;
      // httpClientで使用する共通IFパラメータ等を作成する。
      const config = this.$httpClient.createGetApiRequestConfig();
      // 営業所コード
      config.params.officeCd = sessionStorage.getItem("office_cd");
      return new Promise((resolve, reject) => {
        this.$httpClient
          // 接続先のAPI_URLを入力
          .secureGet(appConfig.API_URL.INI_OFFICE_MODULE, config)
          .then((response) => {
            const jsonData = JSON.parse(JSON.stringify(response.data));
            if (jsonData.resCom.resComCode == appConfig.RESCOMCODE_SUCCESS) {
              const listCnt = jsonData.resIdv.officeModuleList.length;
              if (listCnt > 0) {
                const completeCnt = jsonData.resIdv.officeModuleList.filter(
                  (item) => item.completeFlg === "1"
                ).length;
                if (completeCnt > 0) {
                  this.isCompleteFlg = false;
                }
              }
              resolve(response);
            } else {
              // 処理結果コードが"000"（Success・Retry）以外の場合の処理です。
              this.infoDialog.message = jsonData.resCom.resComMessage;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              // OK NGボタン
              this.infoDialog.firstPageFlag = true;
              // タスクを終了します（失敗）。
              reject(jsonData.resCom.resComMessage);
            }
          })
          .catch((ex) => {
            this.infoDialog.message = ex;
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.screenFlag = true;
            this.infoDialog.firstPageFlag = true;
            reject();
          })
          .finally(() => {
            // ローディング画面表示OFF
            this.loadingCounter = 0;
          });
      });
    },
    pageTransition(page) {
      if (page === "qr") {
        this.$router.push({
          name: appConfig.SCREEN_ID.P_INI_005,
        });
      } else if (page === "label") {
        this.$router.push({
          name: appConfig.SCREEN_ID.P_INI_006,
        });
      } else if (page === "ht") {
        this.$router.push({
          name: appConfig.SCREEN_ID.P_INI_007,
        });
      }
    },
  },
  computed: {},
  watch: {
    // eslint-disable-next-line no-unused-vars
    isMenu: function (newValue, oldValue) {
      if (newValue) {
        this.init();
      }
    },
  },
  mounted() {
    this.init();
  },
  created() {},
};
</script>
<style>
@import "../../css/style.css";
</style>
<style lang="scss" scoped>
.disabled-card {
  opacity: 0.5;
  pointer-events: none;
}
</style>
